export const baseUrl = 'https://api.elcoach.me';
// export const baseUrl = 'https://stage.elcoach.me';
// export const baseUrl = 'http://192.168.1.5:3500';
//export const baseUrl = "http://localhost:3000";

//export const STRIPE_KEY = "pk_test_qqIK0KS669dsCYbGNUl0LiNh00Rwzi6311";
export const STRIPE_KEY = 'pk_live_Cy7buZoNv8OrWQ1DMxZc4JKZ003NPf5D7h';
export const CHECKOUT_KEY = 'pk_d4e22enmhcchvtp7ibyv7tng4yy';
export const APPLE_MERCHANT_ID = 'merchant.elcoach.website';

const OPERATOR_LOGO_BASE_PATH = '../../assets/images/operators/logos';
const SOCIAL_MEDIA_BASE_PATH = '../../assets/images/operators';
// OPERATORS
export const OPERATORS_INFO = [
  {
    country: 'EG',
    countryCode: '20',
    slugs: ['orangeeg', 'vodafoneeg', 'etisalateg', 'weeg'],
    regexPattern: /^1[0-2,5]\d{8}$/,
    payment: {
      ar: '19.99 جنيه',
      en: '19.99 Egyptian pounds',
    },
    socialMedia: [
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/facebook.svg`,
        href: 'https://www.facebook.com/ElCoachfit',
      },
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/twitter.svg`,
        href: 'https://twitter.com/ElCoachfit',
      },
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/instagram.svg`,
        href: 'https://www.instagram.com/ElCoachfit',
      },
    ],
    operators: [
      {
        operatorName: 'Orange',
        operatorSlug: 'orangeeg',
        operatorTitle: {
          ar: 'اورنج',
          en: 'Orange',
        },
        operatorCode: 60201,
        cancelationCode: 5030,
        operatorOTPLength: 6,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/orange.svg`,
        phoneInputBtnText: {
          ar: '',
          en: '',
        },
        disclaimer: {
          ar: [
            'سوف تقوم بالاشتراك فى الكوتش مقابل 19.99 جنية مصري في الأسبوع تجدد تلقائيا مع 3 أيام تجربة مجانية',
            'لإلغاء الاشتراك، لعملاء أورانج ارسل UNSUB CH إلى 5030 مجانا أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'للدعم والاستفسارات، برجاء التواصل معنا على help@elcoach.me.',
            'استهلاك الانترنت سوف يخصم من الباقة الخاصة بك',
          ],
          en: [
            'You will subscribe in ElCoach for 19.99 Egyptian pounds weekly auto-renewed with 3 days free trial.',
            'To cancel your subscription, for Orange subscribers, please send UNSUB CH to 5030 for free Or from the app by going to the subscription plan on the settings page and clicking Cancel Subscription.',
            'For any inquiries please contact us by email at help@elcoach.me',
            'Internet usage is deducted from your internet bundle.',
          ],
        },
      },
      {
        operatorName: 'Vodafone',
        operatorSlug: 'vodafoneeg',
        operatorTitle: {
          ar: 'ڤودافون',
          en: 'Vodafone',
        },
        operatorCode: 60202,
        cancelationCode: 2303,
        operatorOTPLength: 6,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/vodafone.svg`,
        phoneInputBtnText: {
          ar: '',
          en: '',
        },
        disclaimer: {
          ar: [
            'سوف تقوم بالاشتراك فى الكوتش مقابل 19.99 جنية مصري في الأسبوع تجدد تلقائيا مع 3 أيام تجربة مجانية',
            'لإلغاء الاشتراك، لعملاء فودافون ارسل UNSUB CH إلى 2303 مجانا أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'للدعم والاستفسارات، برجاء التواصل معنا على help@elcoach.me.',
            'استهلاك الانترنت سوف يخصم من الباقة الخاصة بك',
          ],
          en: [
            'You will subscribe in ElCoach for 19.99 Egyptian pounds weekly auto-renewed with 3 days free trial.',
            'To cancel your subscription, for Vodafone subscribers, please send UNSUB CH to 2303 for free Or from the app by going to the subscription plan on the settings page and clicking Cancel Subscription.',
            'For any inquiries please contact us by email at help@elcoach.me',
            'Internet usage is deducted from your internet bundle.',
          ],
        },
      },
      {
        operatorName: 'Etisalat',
        operatorSlug: 'etisalateg',
        operatorTitle: {
          ar: 'إتصالات',
          en: 'Etisalat',
        },
        operatorCode: 60203,
        cancelationCode: 1722,
        operatorOTPLength: 6,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/etisalat.svg`,
        phoneInputBtnText: {
          ar: '',
          en: '',
        },
        disclaimer: {
          ar: [
            'سوف تقوم بالاشتراك فى الكوتش مقابل 19.99 جنية مصري في الأسبوع تجدد تلقائيا مع 3 أيام تجربة مجانية',
            'لإلغاء الاشتراك، لعملاء إتصالات ارسل UNSUB CH إلى 1722 مجانا أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'للدعم والاستفسارات، برجاء التواصل معنا على help@elcoach.me.',
            'استهلاك الانترنت سوف يخصم من الباقة الخاصة بك',
          ],
          en: [
            'You will subscribe in ElCoach for 19.99 Egyptian pounds weekly auto-renewed with 3 days free trial.',
            'To cancel your subscription, for Etisalat subscribers, please send UNSUB CH to 1722 for free Or from the app by going to the subscription plan on the settings page and clicking Cancel Subscription.',
            'For any inquiries please contact us by email at help@elcoach.me',
            'Internet usage is deducted from your internet bundle.',
          ],
        },
      },
      {
        operatorName: 'WE',
        operatorSlug: 'weeg',
        operatorTitle: {
          ar: 'وي',
          en: 'WE',
        },
        operatorCode: 60204,
        cancelationCode: 4041,
        operatorOTPLength: 6,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/we.svg`,
        phoneInputBtnText: {
          ar: 'اشترك الآن',
          en: '',
        },
        disclaimer: {
          ar: [
            'استمتع بفترة تجريبية مجانية لمدة ٣ أيام في "الكوتش" تطبيق اللياقة الأول في العالم العربي.',
            'بعد انتهاء الفترة التجريبية، ستتم إعادة تجديد الخدمة تلقائيًا مقابل 19.99 جنيه مصري فقط في الأسبوع.',
            'لإلغاء الاشتراك لعملاء وي ارسل UNSUB CH إلى 4041 مجانًا أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك. ',
            'إذا كان لديك أي استفسارات، يمكنك التواصل معنا عبر البريد الإلكتروني help@elcoach.me.',
            'استهلاك الانترنت سوف يخصم من الباقة الخاصة بك',
          ],
          en: [
            'Enjoy a free 3-day trial period on "ElCoach", the leading fitness app in the Arab world.',
            'After the free trial, the service will automatically renew for only EGP 19.99 per week.',
            'To unsubscribe for WE customers, send UNSUB CH to 4041 for free, or within the app, go to the subscription plan in the settings page and click on Cancel Subscription.',
            'If you have any inquiries, you can contact us via email at help@elcoach.me.',
            'Internet usage is deducted from your internet bundle.',
          ],
        },
      },
    ],
  },
  {
    country: 'SA',
    countryCode: '966',
    slugs: ['mobily', 'zain'],
    regexPattern: /^(5)\d{8}\b/,
    payment: {
      ar: '٣ أيام مجانًا، ثم ١٢ ريال',
      en: '3 Days Free, then 12 SAR',
    },
    socialMedia: [
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/tiktok.svg`,
        href: 'https://www.tiktok.com/@elcoachfit_ksa',
      },
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/facebook.svg`,
        href: 'https://www.facebook.com/ElCoachfit',
      },
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/twitter.svg`,
        href: 'https://twitter.com/elcoach_ksa',
      },
      {
        img: `${SOCIAL_MEDIA_BASE_PATH}/instagram.svg`,
        href: 'https://instagram.com/elcoachapp',
      },
    ],
    operators: [
      // {
      //   operatorName: 'STC',
      //  operatorTitle: {
      //    ar: 'STC',
      //    en: 'STC',
      //  },
      //   operatorCode: 42001,
      // },
      {
        operatorName: 'Mobily',
        operatorSlug: 'mobily',
        operatorTitle: {
          ar: 'موبايلي',
          en: 'Mobily',
        },
        operatorCode: 42003,
        operatorOTPLength: 4,
        cancelationCode: 600990,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/mobily.svg`,
        phoneInputBtnText: {
          ar: 'ابدأ الآن',
          en: 'Start NOW',
        },
        disclaimer: {
          ar: [
            'استمتع بفترة تجريبية مجانية لمدة 3 أيام في تطبيق اللياقة الأول في الوطن العربي "الكوتش".',
            // 'لإلغاء اشتراكك، يرجى إرسال Cancel إلى 600990 أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'ستتم إعادة تجديد الخدمة تلقائيًا بقيمة 12 ريال سعودي فقط أسبوعيًا.',
            'بإمكانك إلغاء الاشتراك في أي وقت، من خلال التطبيق عبر الذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'إذا كان لديك أي استفسارات، يمكنك التواصل معنا عبر البريد الإلكتروني help@elcoach.me.',
          ],
          en: [
            'Get a free 3-day trial on ElCoach, the leading fitness application in the Arab world.',
            'The service will be automatically renewed for only 12 SAR/week.',
            // "To cancel your subscription, please send 'cancel' to 600990 Or from the app by going to the subscription plan on the settings page and clicking Cancel Subscription.",
            'You can cancel your subscription at any time through the application by going to the subscription plan in the settings page and clicking on Cancel Subscription.',
            'If you have any inquiries, you can contact us via email at help@elcoach.me.',
          ],
        },
      },
      {
        operatorName: 'Zain',
        operatorSlug: 'zain',
        operatorTitle: {
          ar: 'زين',
          en: 'Zain',
        },
        operatorCode: 42004,
        operatorOTPLength: 4,
        cancelationCode: 708900,
        withOTPBtnConfirmation: true,
        removeNextRenewalTime: true,
        img: `${OPERATOR_LOGO_BASE_PATH}/zain.svg`,
        phoneInputBtnText: {
          ar: 'أحصل على الرمز التعريفي',
          en: 'Get Pin',
        },
        disclaimer: {
          ar: [
            'سوف تشترك في الكوتش مقابل 12 ريال سعودي في الأسبوع مع 3 أيام تجربة مجانية.',
            'لإلغاء الخدمة برجاء ارسال رسالة نصية تحتوي على كلمة UNSUBCW الى رقم 708900 أو من داخل التطبيق بالذهاب إلى خطة الاشتراك في صفحة الإعدادات والضغط على إلغاء الاشتراك.',
            'للدعم والاستفسارات، برجاء التواصل معنا على help@elcoach.me.',
          ],
          en: [
            'You will subscribe in ElCoach for 12 SAR weekly with 3 days free trial.',
            "To cancel your subscription, please send 'UNSUBCW' to 708900 Or from the app by going to the subscription plan on the settings page and clicking Cancel Subscription.",
            'For any inquires, please contact help@elcoach.me.',
          ],
        },
      },
    ],
  },
];
