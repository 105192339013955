




export function setItemToLocalStorage(key, object) { 
    if (typeof window !== 'undefined') {
    return localStorage.setItem("elcoach-"+key, JSON.stringify(object));
    }
}

export function getItemFromLocalStorage(key) { 
    if (typeof window !== 'undefined') {
    var retrievedObject = localStorage.getItem("elcoach-"+key);
    return JSON.parse(retrievedObject)
    }
}

export function deleteFromLocalStorage(key) { 
    if (typeof window !== 'undefined') {
    return localStorage.removeItem("elcoach-"+key);
    }
}

