const querystring = require("querystring");

class challenge {
  constructor(baseURL = "", axios = {}) {
    this.baseURL = baseURL;
    this.axios = axios;
  }

  store(item) {
    console.log("store here");
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/store",
        data: item,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.item);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  update(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/update/" + item.id,
        data: item,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.item);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getAll(form) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/query",
        data: form,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  get(id) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.baseURL + "/challenge/get/" + id,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.item);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getPublicInfo(entity_id) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.baseURL + "/group/info/?entity_id=" + entity_id,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  registerAndJoin(id, data) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/group/join/" + id,
        data: data,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          lang: "ar",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  del(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/delete/" + item.id,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.status);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  subscribe(item, answers = {}) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/subscribe/" + item.id,
        data: answers,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.item);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  unsubscribe(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/unsubscribe/" + item.id,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.status);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getActiveChallenges() {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.baseURL + "/challenge/active",
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getLeaderBoard({
    id,
    metric = "",
    fromDayNumber,
    toDayNumber,
    fromYear,
    toYear,
  }) {
    const params = new URLSearchParams();
    if (metric) params.append("metric", metric);
    if (fromDayNumber) params.append("fromDayNumber", fromDayNumber);
    if (toDayNumber) params.append("toDayNumber", toDayNumber);
    if (fromYear) params.append("fromYear", fromYear);
    if (toYear) params.append("toYear", toYear);

    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: `${this.baseURL}/challenge/leaderBoard/${id}?${params.toString()}`,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  uploadInBody(formData) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/inbody",
        data: formData,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.user_info);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  uploadInBodyBase64(image) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/inbody/base64",
        data: { image: image },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.user_info);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  addToBlackList(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/blacklist/" + item.id,
        data: item,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  removeFromBlackList(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/removeBlacklist/" + item.id,
        data: item,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getUsers(form) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/users/" + form.form.id,
        data: form,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getUserInfo(challengeId, userId) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url:
          this.baseURL +
          "/challenge/user/" +
          challengeId +
          "?user_id=" +
          userId,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getGroups() {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.baseURL + `/groups`,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  changeStatus(item) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "post",
        url: this.baseURL + "/challenge/status/" + item.id,
        data: item,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }
}

class EndPoints {
  constructor(baseURL = "", axios = {}) {
    this.baseURL = baseURL;
    this.apiUrl = baseURL + "/api";
    this.axios = axios;
    this.Challenge = new challenge(this.apiUrl, axios);
  }

  Sanitize(form) {
    if (form.createdAt != undefined) delete form.createdAt;
    if (form.updatedAt != undefined) delete form.updatedAt;
    if (form.__typename != undefined) delete form.__typename;

    return form;
  }

  GetErrorData(err) {
    try {
      if (err) {
        if (err.response) {
          if (err.response.data) {
            return err.response.data;
          }
        }
      }
      return err;
    } catch (error) {
      return err;
    }
  }

  InitTestData(type = "all") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/init_test_data?type=" + type,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgressLog(count, type) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/progress",
          data: querystring.stringify({ count, type }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgressLogBulk(items) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/progress/bulk",
          data: { items: items },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Login(username, password) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            username: username,
            password: password,
            grant_type: "password",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data.access_token);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  WebLogin(username, password) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            username: username,
            password: password,
            grant_type: "password",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          validateStatus: () => true,
        })
        .then(function (res) {
          if (res.status == 200) {
            resolve(res.data.access_token);
          } else {
            reject(self.GetErrorData(res));
          }
        });
    });
  }

  FacebookLogin(user_id, email, name, photo) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            user_id: user_id,
            email: email,
            name: name,
            photo: photo,
            grant_type: "social",
            network: "facebook",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data.access_token);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FacebookRegister(user_id, email, name, photo, info = {}) {
    var self = this;

    let form = {};
    form.user_id = user_id;
    form.email = email;
    form.name = name;
    form.photo = photo;
    form.network = "facebook";
    form.info = info;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/social_register",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GoogleRegister(user_id, email, name, photo, info = {}) {
    var self = this;

    let form = {};
    form.user_id = user_id;
    form.email = email;
    form.name = name;
    form.photo = photo;
    form.network = "google";
    form.info = info;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/social_register",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GoogleLogin(user_id, email, name, photo) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            user_id: user_id,
            email: email,
            name: name,
            photo: photo,
            grant_type: "social",
            network: "google",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data.access_token);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GuestRegister(user_id, email, name, photo, info = {}) {
    var self = this;

    let form = {};
    form.user_id = user_id;
    form.email = email;
    form.name = name;
    form.photo = photo;
    form.network = "guest";
    form.info = info;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/social_register",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GuestLogin(user_id, email, name, photo) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            user_id: user_id,
            email: email,
            name: name,
            photo: photo,
            grant_type: "social",
            network: "guest",
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data.access_token);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserMe() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/user/me",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserVerify(username, code) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/user/verify",
          data: JSON.stringify({ username, code }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserUpdateInfo(info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user/info",
          data: JSON.stringify({ info }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserUpdateInfoWeb(info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user/info",
          data: info,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserRegister(form) {
    var self = this;

    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/register",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserChangePassword(currentPassword, password) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user/password",
          data: JSON.stringify({ currentPassword, password }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserChangePasswordByCode(username, code, password) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user/password_by_code",
          data: JSON.stringify({ username, code, password }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserResetPassword(username) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/reset_password",
          data: JSON.stringify({ username }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  LogUserActions(actions, calc_points = true) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/useraction/log_actions",
          data: JSON.stringify({ actions, calc_points }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  StartupData(
    os = "android",
    app_ver = "",
    app_build = "",
    isHuawei = false,
    now = "",
    cp_ver = "",
    os_ver = "",
    userid = ""
  ) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            `/startupdata?app_ver=${app_ver}&app_build=${app_build}&os=${os}&os_ver=${os_ver}&cp_ver=${cp_ver}&isHuawei=${isHuawei}&now=${now}&userid=${userid}`,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  CodePushConfig(
    ac = "",
    os = "android",
    app_ver = "",
    app_build = "",
    isHuawei = false,
    env = "Production"
  ) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            `/cp_config?ac=${ac}&app_ver=${app_ver}&app_build=${app_build}&os=${os}&isHuawei=${isHuawei}&env=${env}`,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SwapAlternativeMealWithCurrent(from, to) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/sub/replace_element",
          data: JSON.stringify({ from, to }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetNutrSamples() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/nutSample",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetNutrQuestions() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/nutScript",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Trainers(answers) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/trainers",
          data: querystring.stringify(answers),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubMatchAndChange(info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/match_and_change",
          data: JSON.stringify({ info }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  AddSubscription(form) {
    var self = this;

    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/subscription",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ChangeSubscriptionStatus(form) {
    var self = this;

    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/subscription/status",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubscriptionInfo() {
    var self = this;

    var user_id = 0;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/subscription/user_info",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubFollow(
    sub_id,
    program_id,
    day,
    week,
    type = 1,
    feedback = null,
    day_type = 1,
    workout_time
  ) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/follow",
          data: JSON.stringify({
            sub_id,
            program_id,
            day,
            week,
            type,
            feedback,
            day_type,
            workout_time,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MealFeedback(feedback, mealInfo) {
    var self = this;
    var feedback_value = feedback == "happy" ? 2 : feedback == "good" ? 1 : 0;
    let element_id = mealInfo ? mealInfo.recipe_id : 0;
    element_id = element_id.toString().includes("-")
      ? parseInt(element_id.split("-")[0])
      : element_id;
    var info = mealInfo.gamification;

    let reqObj = {
      feedback_value,
      element_id,
      info,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/feedback",
          data: querystring.stringify(reqObj),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  InviteActivate(token, promo) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/invite/activate",
          data: querystring.stringify({
            token,
            promo,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FlashDeactive(id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/flash/deactivate" + "/" + id,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranAdd(form) {
    var self = this;

    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranWithSubAdd(sub_form, tran_form, stripe_form = {}) {
    var self = this;

    try {
      sub_form = self.Sanitize(sub_form);
      tran_form = self.Sanitize(tran_form);
    } catch (error) {}
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/with_sub",
          data: JSON.stringify({ sub_form, tran_form, stripe_form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranQuery(where = [], order = [], page = 1, size = 10, username) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/query",
          data: JSON.stringify({ form, username }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Tran(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/tran/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  DeferPaymentAndroid(period) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl + "/tran/defer_payment_android?" + "period=" + period,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PromoCheck(code, period) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/promo/checkCode?" +
            "code=" +
            encodeURIComponent(code) +
            "&" +
            "period=" +
            period,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SinglePromoCheck(code, period, tran_id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/promo/activate?",
          data: querystring.stringify({
            code: code,
            tranId: tran_id || 0,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubUpdateInfo(sub_id, info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/sub/info",
          data: JSON.stringify({ sub_id, info }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PostsHome() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/posts/home",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  RateApp(rate, message = "") {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/user/rate_app",
          data: querystring.stringify({
            rate,
            message,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  InviteMylist() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/invite/my_list",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Profile() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/sub/profile",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Flashes() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/flash/userFlashes",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  addFlash(form, type, user_id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/flash",
          data: JSON.stringify({ form, type, user_id }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  EventLog(name, content) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/event",
          data: querystring.stringify({
            name,
            content,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserProgressUpload(image) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/user_progress",
          data: querystring.stringify({
            data: image,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  uploadImageBodyChallengeBase64(image) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/challenge/user/image",
          data: { image: image },
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserProgressGet(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "GET",
          url: self.apiUrl + "/user_progress/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserProgressDel(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "DELETE",
          url: self.apiUrl + "/user_progress/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserShareUpload(image) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/share",
          data: querystring.stringify({
            data: image,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranLog(info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/log",
          data: JSON.stringify({ info }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserSendMessage(data) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/send_message",
          data: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  BillingNotificationAndroid(message) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/billing_notification",
          data: JSON.stringify(message),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  BillingNotificationIOS(message) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/billing_notification_ios",
          data: JSON.stringify(message),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FawryProcessedCallback(message) {
    let self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/fawry_processed_callback?" +
            "MerchantRefNo=" +
            message.MerchantRefNo +
            "&" +
            "FawryRefNo=" +
            message.FawryRefNo +
            "&" +
            "OrderStatus=" +
            message.OrderStatus +
            "&" +
            "Amount=" +
            message.Amount +
            "&" +
            "MessageSignature=" +
            message.MessageSignature,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  CheckCountry() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/country",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubInfo() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/sub_info",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  WorkoutProgram(user_id = null) {
    var self = this;
    var path = "/workout_program";
    if (user_id) {
      path = `/workout_program?user_id=${user_id}`;
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  NutProgram(user_id = null) {
    var self = this;
    var path = "/nut_program";
    if (user_id) {
      path = `/nut_program?user_id=${user_id}`;
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  NutProgramNextWeek(user_id = null) {
    var self = this;
    var path = "/nut_program_nw";
    if (user_id) {
      path = `/nut_program_nw?user_id=${user_id}`;
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  NutProgramRefresh(user_id = null) {
    var self = this;
    var path = "/nut_program_refresh";
    if (user_id) {
      path = `/nut_program_refresh?user_id=${user_id}`;
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SupplProgram(user_id = null) {
    var self = this;
    var path = "/suppl_program";
    if (user_id) {
      path = `/suppl_program?user_id=${user_id}`;
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetProfile(date, faqs = true) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/get_profile?" + `date=${date}&faqs=${faqs}`,
        })
        .then(function (res) {
          resolve(res.data.data.Profile);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FAQQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/faqs/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FAQ(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/faqs/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FAQUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/faqs",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FAQAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/faqs",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FAQTags() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/faqs/tags",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2Query(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2QueryExport(where = [], order = []) {
    var self = this;
    let form = {
      where,
      order,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          responseType: "blob",
          url: self.apiUrl + "/elementv2/queryExport",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "elements.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          resolve(true);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2ByName(name, type, sub_type) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/by_name",
          params: {
            name,
            type,
            sub_type,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2Search(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2/search",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2All() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/all",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2Update(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/elementv2",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2Add(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Elementv2Tags() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/elementv2/tags",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Post(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/posts/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PostAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/posts",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PostUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/posts",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PostQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/posts/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ParserPlay(payload) {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2/parser_play",
          data: JSON.stringify({ payload }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramMatch(payload) {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/match",
          data: JSON.stringify({ payload }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramMatchDebug(payload) {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/match_debug",
          data: JSON.stringify({ payload }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubUserInfo(user_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/sub/user_info/" + user_id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Promo(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/promo/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PromoAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/promo",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PromoUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/promo",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PromoQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/promo/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Quotes(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/quotes/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  QuotesAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/quotes",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  QuotesUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/quotes",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  QuotesQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/quotes/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramCompile(id, options = {}) {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/program_compile",
          data: JSON.stringify({ id, options }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramAnalysis(id, options) {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/program_analysis",
          data: JSON.stringify({ id, options }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Program(id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/program/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/program",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  User(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/user/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  userPublicInfo(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/userPublicInfo?id=" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserAssignProgram(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/sub/user_assign_program",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TrainersList() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/user/trainers_list",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PermissionsList() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/user/permissions_list",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  LogQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/log/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Log(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/log/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserChangeAvatar(image) {
    const self = this;
    return new Promise((resolve, reject) => {
      self
        .axios({
          method: "POST",
          url: self.apiUrl + "/update_user_photo",
          data: querystring.stringify({
            data: image,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(self.GetErrorData(err));
        });
    });
  }

  ClearNutCache(user_id = null) {
    const self = this;
    var path = "/nut_program_clear";
    if (user_id) {
      path = `/nut_program_clear?user_id=${user_id}`;
    }
    return new Promise((resolve, reject) => {
      self
        .axios({
          method: "get",
          url: self.apiUrl + path,
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripeProductsAndPlans(unit, market_cur = "") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/payment/products?unit=" +
            unit +
            "&market_cur=" +
            market_cur,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripePartnerPlans(unit, partner, market_cur = "") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/payment/partnerproducts?unit=" +
            unit +
            "&partner=" +
            partner +
            "&market_cur=" +
            market_cur,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripePromoProduct(unit, code, period, market_cur = "") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/payment/promoproduct?unit=" +
            unit +
            "&code=" +
            code +
            "&period=" +
            period +
            "&market_cur=" +
            market_cur,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripeOfferProduct(unit, id, period, market_cur = "") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/payment/offerproduct?unit=" +
            unit +
            "&id=" +
            id +
            "&period=" +
            period +
            "&market_cur=" +
            market_cur,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripeSetupIntent() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/payment/setupIntent",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripePaymentIntent(amount, currency) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            `/payment/paymentIntent?amount=${amount}&currency=${currency}`,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SessionProgram(id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/sessions/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SessionProgramQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sessions/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SessionProgramAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sessions",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SessionProgramUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/sessions",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramSession() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/sessions/",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SubscribeToSession(sessionId) {
    var self = this;
    var form = self.Sanitize({ session_id: sessionId });
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sessions/subscribe",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetChallenges() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/program/challenges",
        })
        .then(function (res) {
          resolve({ challenges: res.data });
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ClearChallenge(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "delete",
          url: self.apiUrl + "/program/challenge/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetFastWorkouts() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/program/fws",
        })
        .then(function (res) {
          resolve({ fws: res.data });
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetChallengeDetails(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/program/challenge/" + id + "?data=full",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  mobileLog(info) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/log/mobile",
          data: JSON.stringify(info),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  revokeHuaweiSub(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/huawei/revoke_subscription",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SocialLogin(
    network,
    user_id,
    email,
    name,
    photo,
    code = "",
    channel = "sms"
  ) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/oauth/token",
          data: querystring.stringify({
            user_id: user_id,
            email: email,
            name: name,
            photo: photo,
            grant_type: "social",
            network: network,
            code: code,
            channel: channel,
          }),
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (res) {
          resolve(res.data.access_token);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SocialRegister(
    network,
    user_id,
    email,
    name,
    photo,
    info = {},
    code = "",
    channel = "sms"
  ) {
    var self = this;

    let form = {};
    form.user_id = user_id;
    form.email = email;
    form.name = name;
    form.photo = photo;
    form.network = network;
    form.info = info;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/social_register",
          data: JSON.stringify({ form, code, channel }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranValidate(os, receiptId) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/tranValidate",
          data: JSON.stringify({ receiptId, os }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MoveFollowDay(sub_id, step) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/moveDay",
          data: JSON.stringify({ sub_id, step }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MakeActiveSub(sub_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/make_active_sub",
          data: JSON.stringify({ sub_id }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ReactivateTran(tran_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/reactivate/" + tran_id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TranQueryCSV(where = [], order = [], username) {
    var self = this;
    let form = {
      where,
      order,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tran/csv_query",
          data: JSON.stringify({ form, username }),
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  OfferProduct(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/offer_product/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  OfferProductAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/offer_product",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  OfferProductUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/offer_product",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  OfferProductQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/offer_product/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MobileOffer(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/mobile_offer/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MobileOfferAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/mobile_offer",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MobileOfferUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/mobile_offer",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  MobileOfferQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/mobile_offer/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetAllOfferProducts() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/offer_product/get_all",
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PartnersPlans(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/partners_plans/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PartnersPlansAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/partners_plans",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PartnersPlansUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/partners_plans",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PartnersPlansQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/partners_plans/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ValidateQRCode(code) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/validateQRCode?code=" + code,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  scoreSchema() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/scoreSchema",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  deleteMe() {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "delete",
          url: self.apiUrl + "/user/delete",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  validateApplePaySession(appleUrl, domainName, merchantIdentifier) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/payment/apple/validateApplePaySession",
          data: JSON.stringify({
            appleUrl,
            domainName,
            merchantIdentifier,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  moveToNextWeek(data) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/moveweek",
          data: JSON.stringify({ ...data }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  autoRenewSubscription(sub_id, cvv) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/payment/mada/autoRenewSubscription",
          data: JSON.stringify({ sub_id, cvv }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  sendOTP(mobile, channel = "sms") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/send_otp",
          data: JSON.stringify({ mobile, channel }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  updateUserMobile(mobile, code, channel = "sms") {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "patch",
          url: self.apiUrl + "/user/updateMobile",
          data: JSON.stringify({ mobile, code, channel }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  CreateDCBContract(info, extraHeaders = {}) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/dcb/create",
          data: JSON.stringify(info),
          headers: {
            "Content-Type": "application/json",
            ...extraHeaders,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  VerifyDCBContract(info, extraHeaders = {}) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/dcb/verify",
          data: JSON.stringify(info),
          headers: {
            "Content-Type": "application/json",
            ...extraHeaders,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ResendDCBPin(info, extraHeaders = {}) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/dcb/resend",
          data: JSON.stringify(info),
          headers: {
            "Content-Type": "application/json",
            ...extraHeaders,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ContentList(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/content",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  addContent(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/content/add",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getContentById(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/content/get_by_id/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  updateContent(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/content/edit/" + form.id,
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  deleteContent(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/content/del/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetStripeIAPProducts(platform, unit, app_ver, user_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url:
            self.apiUrl +
            "/payment/iapproducts?unit=" +
            unit +
            "&platform=" +
            platform +
            "&app_ver=" +
            app_ver +
            "&user_id=" +
            user_id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TAGSQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tags/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TAGSGET(name) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/tags/get/" + name,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TAGSADD(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tags/add",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  TAGSUPDATE(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/tags/update/" + form.name,
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Question(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/auto/question",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UpdateQuestion(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/auto/update",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  LoadChat(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/auto/load",
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ListChats(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/auto/list",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramHistory(userId) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/programs/history/" + userId,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SwapProgramHistory(form) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/programs/history/swap/" + form.id,
          data: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  DisableSubscription() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/subscription/disable",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  GetExtPromo() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/promo/ext",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  FollowCount(filter) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/sub/followCount",
          data: { ...filter },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserProgressGetAll(user_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "GET",
          url: self.apiUrl + "/user_progress_all",
          params: {
            user_id,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserSuperCoachRegister(form) {
    var self = this;

    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/register",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
          validateStatus: () => true,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  CreateUsersSuperCoach(data) {
    var self = this;

    data = self.Sanitize(data);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/clients/add",
          data: JSON.stringify({ data }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  BulkUploadUsersSuperCoach(data) {
    var self = this;

    data = self.Sanitize(data);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/bulk_upload_cients",
          data: data,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  //superCoach Programs
  SuperCoachProgram(id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/superCoach/program/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachProgramQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/program/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachProgramAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/program",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperProgramUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/superCoach/program",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  //superCoach Elements
  SuperCoachElementUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/superCoach/element",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachElementAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/element",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachElementQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/element/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachElement(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/superCoach/element/" + id,
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserCSSSO() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "GET",
          url: self.apiUrl + "/user/cssso",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  UserToken() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "GET",
          url: self.apiUrl + "/user/token",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachGetMessages() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "GET",
          url: self.apiUrl + "/cs/message",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachSendMessages(data) {
    var self = this;
    const formData = new FormData();
    formData.append("message", data.message);
    if (data.attachments.length) {
      for (const attachment of data.attachments) {
        formData.append("attachment", attachment);
      }
    }
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          data: formData,
          url: self.apiUrl + "/cs/message",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  SuperCoachMarkConversationAsRead(conversation_id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "POST",
          data: {
            conversation_id,
          },
          url: self.apiUrl + "/cs/read",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ConfigsQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/config/query",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ConfigByid(id) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/config/" + id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ConfigUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "put",
          url: self.apiUrl + "/config",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getUsersSubscription() {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/group/users_subscription`,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  getPerformedUsers(from, to) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url:
          this.apiUrl +
          `/group/performed?metric=workout_days${
            from != undefined ? `&from=${from}&to=${to}` : ""
          }`,
      })
        .then((res) => {
          if (res.data.status) {
            resolve(res.data.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }

  duplicateSingleProgram(pid) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/clone",
          data: JSON.stringify({ program_id: pid }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  programClonePrivate(pSubId) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/program/clone_private",
          data: JSON.stringify({ sub_id: pSubId }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  AdminChangeUserPassword(user_id) {
    var self = this;

    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/user/admin_reset_password",
          data: JSON.stringify({ user_id }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getExercisesHub(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2/hub",
          data: JSON.stringify({ form }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ProgramCompletedStatus(program_id) {
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/program/status/${program_id}`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch((err) => {
          if (
            err.response !== undefined &&
            err.response.data.errors !== undefined &&
            err.response.data.errors.length > 0
          ) {
            reject(err.response.data.errors);
          }
          reject(err);
        });
    });
  }
  createPrivateTeam({ title, image }) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/team/create/private",
          data: JSON.stringify({
            title,
            info: {
              image,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }
  getMyTeams() {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/teams`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }
  joinTeam(code) {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/team/private_join?code=${code}`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }
  joinPublicTeam(id) {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/team/join/${id}`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }
  leaveTeam(id) {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/team/leave/${id}`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }
  getPublicTeams() {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/teams/suggestions`,
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  deleteUserFromTeam({ teamId, userId }) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + `/team/members/${teamId}/delete`,
          data: JSON.stringify({
            user_id: userId,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  ingredientsQuery(recipe) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/elementv2/ing_query",
          data: JSON.stringify({
            recipe: recipe,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getPaymentProducts() {
    var self = this;
    return new Promise((resolve, reject) => {
      this.axios({
        method: "get",
        url: this.apiUrl + `/payment/products`,
        headers: {
          appname: "supercoach",
        },
      })
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          }
          reject(res.data.errors);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getSuperCoachSubInfo() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/superCoach/subinfo",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  superCoachBuyPlan(clientsCount, productId, stripeProductId, payMethod) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + "/superCoach/buyplan",
          data: JSON.stringify({
            form: {
              stripe_product_id: stripeProductId,
              product_id: productId,
              payment_info: {
                total_users: clientsCount,
              },
              payment_method: payMethod,
            },
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getBillingInfo() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/superCoach/billinginfo",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getBillingPortal(returnUrl, token) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + `/superCoach/billingportal`,
          params: {
            return_url: returnUrl,
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  deleteProgramById(programId) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "post",
          url: self.apiUrl + `/program/delete/${programId}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  deleteElementv2ById(elementId) {
    var self = this;
    return new Promise(function (resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + `/elementv2/delete/${elementId}`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function (res) {
            resolve(res.data);
          })
          .catch(function (err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  getSubsbasePortalToken() {
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + `/subscription/subsbase/token`,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  getChatScript() {
    var self = this;
    this.errors = [];
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/program/script",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });
  }


  TeamMembersQuery (where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
        where,
        order,
        page,
        size,
    };
    return new Promise(function (resolve, reject) {
        self.axios({
            method: 'post',
            url: self.apiUrl + `/v2/membership/queryMembers`,
            data: JSON.stringify({ form }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                resolve(res.data);
            })
            .catch(function (err) {
                reject(self.GetErrorData(err));
            });
    });
  }


  deleteTeamMemberById (userId) {
      var self = this;
      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/membership/delete/` + userId,
              data: JSON.stringify({
                  user_id: userId,
              }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });
  }

  BranchesQuery (where = [], order = [], page = 1, size = 10) {
      var self = this;
      let form = {
          where,
          order,
          page,
          size,
      };
      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/branches/query`,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });
  }

  getUserBranches (userId) {
      var self = this;
      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'get',
              url: self.apiUrl + `/v2/branches/getUserBranches/` + userId,
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });
  }

  updateBranch (id , form) {
      var self = this;
      form = self.Sanitize(form);

      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/branches/update/` + id,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });
  }

  updateTeamMember (userId , form) {
      var self = this;
      form = self.Sanitize(form);

      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/membership/update/` + userId,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      }); 
  }

  AddUserToMultiBranches (userId , form) {
    var self = this;
    form = self.Sanitize(form);

    return new Promise(function (resolve, reject) {
        self.axios({
            method: 'post',
            url: self.apiUrl + `/v2/branches/assignMultiple/` + userId,
            data: JSON.stringify({ form }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(function (res) {
                resolve(res.data);
            })
            .catch(function (err) {
                reject(self.GetErrorData(err));
            });
    });
  }

  AddUserToBranch( branchId ,form ) {
      var self = this;
      form = self.Sanitize(form);

      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/branches/${branchId}/assignUser`,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });

  }

  AddTeamMember (form) {
      var self = this;
      form = self.Sanitize(form);

      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/membership/add`,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });

  }


  getMember (userId) {

      var self = this;
      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'get',
              url: self.apiUrl + `/v2/membership/getMember/` + userId,
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });

  }


  getBranch (id) {

      var self = this;
      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'get',
              url: self.apiUrl + `/v2/branches/` + id,
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });
  }

  AddBranch (form) {
      var self = this;
      form = self.Sanitize(form);

      return new Promise(function (resolve, reject) {
          self.axios({
              method: 'post',
              url: self.apiUrl + `/v2/branches/add`,
              data: JSON.stringify({ form }),
              headers: {
                  'Content-Type': 'application/json',
              },
          })
              .then(function (res) {
                  resolve(res.data);
              })
              .catch(function (err) {
                  reject(self.GetErrorData(err));
              });
      });        
  }

  DeleteUserById (user_id) {

    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "delete",
          url: self.apiUrl + "/user/deleteUser/" + user_id,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    }); 
  
  }

  getSuperCoachSubInfoForMemeber(){
    var self = this;
    return new Promise(function (resolve, reject) {
      self
        .axios({
          method: "get",
          url: self.apiUrl + "/superCoach/subInfoByGroup",
        })
        .then(function (res) {
          resolve(res.data);
        })
        .catch(function (err) {
          reject(self.GetErrorData(err));
        });
    });

  }
  
  queryTasks(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/queryTasks',
        data: JSON.stringify({ form }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  taskAdd(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/task',
        data: JSON.stringify({ form }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  taskUpdate(form, taskId) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + `/taskUpdate/${taskId}`,
        data: JSON.stringify({ ...form }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  assignTask(form) {
    var self = this;
    form = self.Sanitize(form);

    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + `/tasks/assign`,
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  getTaskById(task_id) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'get',
        url: self.apiUrl + `/task/` + task_id,
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  DeleteTaskById(task_id) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'delete',
        url: self.apiUrl + '/task/' + task_id,
        headers: {
          'Content-Type': 'application/json',
        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  TasksPreview(user_id , page , limit ) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'get',
        url: self.apiUrl + `/tasks/preview/${user_id}?page=${page}&limit=${limit}`,
        headers: {
          'Content-Type': 'application/json',

        },
      })
          .then(function(res) {
            resolve(res.data);
          })
          .catch(function(err) {
            reject(self.GetErrorData(err));
          });
    });
  }

  PackagesQuery(where = [], order = [], page = 1, size = 10) {
    var self = this;
    let form = {
      where,
      order,
      page,
      size,
    };
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/superCoach/userPlansQuery',
        data: JSON.stringify({ form }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(function(res) {
          resolve(res.data);
        })
        .catch(function(err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  Packagedelete(id) {
    var self = this;
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/superCoach/deletePackage',
        data: JSON.stringify({ id }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(function(res) {
          resolve(res.data);
        })
        .catch(function(err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PackagesCreate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/superCoach/createPackage',
        data: JSON.stringify({ form }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(function(res) {
          resolve(res.data);
        })
        .catch(function(err) {
          reject(self.GetErrorData(err));
        });
    });
  }

  PackagesUpdate(form) {
    var self = this;
    form = self.Sanitize(form);
    return new Promise(function(resolve, reject) {
      self.axios({
        method: 'post',
        url: self.apiUrl + '/superCoach/updatePackage',
        data: JSON.stringify(form),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(function(res) {
          resolve(res.data);
        })
        .catch(function(err) {
          reject(self.GetErrorData(err));
        });
    });
  }

}

module.exports = EndPoints;
