/* eslint-disable import/prefer-default-export */
// TODO: init axios instance here....

import EndPoints from '../endpoints/endpoints/web_endpoints';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
} from '../helpers/local_storage_manger';
import axios from 'axios';
import { baseUrl } from '../constants';

function getParamValue(param) {
  try {
    if (window) {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = (params[param] || '').toLowerCase();
      return value;
    }
  } catch (error) { }

  return '';
}

function Init(ac = '', appos) {
  if (ac == '') ac = getItemFromLocalStorage('AC');

  let instance = axios.create();
  var token = ac == '' ? getItemFromLocalStorage('AC') : ac;
  if (token && token != '') {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
  instance.defaults.headers.common['elcoach-ver'] = 'website';
  instance.defaults.headers.common['appos'] = appos;
  return new EndPoints(baseUrl, instance);
}

export const getProducts = async market_cur => {
  var endPoints = Init();
  var plans = await endPoints.GetStripeProductsAndPlans('month');
  return plans || [];
};

export const getPartnerPlans = async (partner, market_cur) => {
  var endPoints = Init();
  var product = await endPoints.GetStripePartnerPlans(
    'month',
    partner
  );
  return product || {};
};

export const getPromoProduct = async (code, period, market_cur) => {
  var endPoints = Init();
  var product = await endPoints.GetStripePromoProduct(
    'month',
    code,
    period
  );
  return product || {};
};

export const getOfferProduct = async (id, period, platform, market_cur) => {
  var endPoints = Init(null, platform);
  var product = await endPoints.GetStripeOfferProduct(
    'month',
    id,
    period
  );
  return product || {};
};

export const getStripSetupIntent = async () => {
  var endPoints = Init();
  return endPoints.GetStripeSetupIntent();
};
export const getStripPaymentIntent = async (amount, currency) => {
  var endPoints = Init();
  return endPoints.GetStripePaymentIntent(amount, currency);
};

export const tranwithSubAndCardAdd = async (
  sub_form,
  tran_form,
  stripe_form,
) => {
  var endPoints = Init();
  if (window.glpoint) {
    if (tran_form.payment_info) {
      tran_form.payment_info.glPoint = window.glpoint;
    }
  }
  return endPoints.TranWithSubAdd(sub_form, tran_form, stripe_form);
};

export const subAdd = async sub_form => {
  var endPoints = Init();
  return endPoints.AddSubscription(sub_form);
};

export const tranAdd = async tran_form => {
  var endPoints = Init();
  if (window.glpoint) {
    if (tran_form.payment_info) {
      tran_form.payment_info.glPoint = window.glpoint;
    }
  }
  return endPoints.TranAdd(tran_form);
};

export const UserRegister = async form => {
  var endPoints = Init();
  return endPoints.UserRegister(form);
};

export const getUserInfo = async (getSubInfo = true) => {
  var endPoints = Init();
  let userInfo = await UserMe();
  let subInfo = {};
  if (getSubInfo) {
    subInfo = await SubscriptionInfo();
  }
  if (!getSubInfo) {
    if (typeof userInfo == 'object') {
      const { first_name, last_name, info } = userInfo;
      const { profile_pic } = userInfo.info;
      setItemToLocalStorage('profileInfo', {
        name: first_name + last_name || info.name,
        profile_pic,
      });
    }
    return { userInfo };
  }
  if (typeof userInfo == 'object' && typeof subInfo == 'object') {
    const { first_name, last_name, info } = userInfo;
    const { profile_pic } = userInfo.info;
    setItemToLocalStorage('profileInfo', {
      subInfo,
      name: first_name + last_name || info.name,
      profile_pic,
    });
  }
  return { userInfo, subInfo };
};

export const UserLoginByToken = async (accessToken, getSubInfo = true) => {
  return new Promise(async function (resolve, reject) {
    try {
      if (accessToken) {
        setItemToLocalStorage('AC', accessToken);

        let { userInfo, subInfo } = await getUserInfo(getSubInfo);
        setItemToLocalStorage('userInfo', userInfo);

        if (typeof userInfo == 'object') {
          if (!getSubInfo) {
            resolve('loginsuccess');
          } else if (getSubInfo && typeof subInfo == 'object') {
            setTimeout(() => {
              resolve('loginsuccess');
            }, 1000);
          }
        } else {
          reject('Error Occured');
        }
      }
    } catch (error) {
      console.log('error =>', error);
      if (error.error_description) {
        reject(error?.error_description);
      }
      reject(error?.response?.data || error);
    }
  });
};

export const UserLogin = async (username, password) => {
  var endPoints = Init();
  return new Promise(async function (resolve, reject) {
    try {
      let accessToken = await endPoints.Login(username, password);

      if (accessToken) {
        setItemToLocalStorage('AC', accessToken);

        let { userInfo, subInfo } = await getUserInfo();
        setItemToLocalStorage('userInfo', userInfo);

        if (typeof userInfo == 'object' && typeof subInfo == 'object') {
          resolve('loginsuccess');
        } else {
          reject('Error Occured');
        }
      }
    } catch (error) {
      console.log('error =>', error);
      if (error.error_description) {
        reject(error?.error_description);
      }
      reject(error?.response?.data || error);
    }
  });
};

export const UserMe = async () => {
  var endPoints = Init();
  return endPoints.UserMe();
};

export const FacebookRegister = async (
  user_id,
  email,
  name,
  photo,
  info = {},
) => {
  info.reg_from = 'website';
  var endPoints = Init();
  return endPoints.FacebookRegister(user_id, email, name, photo, info);
};

export const FacebookLogin = async (user_id, email, name, photo) => {
  var endPoints = Init();
  return new Promise(async function (resolve, reject) {
    try {
      let accessToken = await endPoints.FacebookLogin(
        user_id,
        email,
        name,
        photo,
      );

      if (accessToken) {
        setItemToLocalStorage('AC', accessToken);

        let { userInfo, subInfo } = await getUserInfo();
        setItemToLocalStorage('userInfo', userInfo);

        if (typeof userInfo == 'object' && typeof subInfo == 'object') {
          resolve('loginsuccess');
        } else {
          reject('Error Occured');
        }
      }
    } catch (error) {
      console.log('error =>', error);
      if (error.error_description) {
        reject(error?.error_description);
      }
      reject(error?.response?.data || error);
    }
  });
};

export const GoogleRegister = async (
  user_id,
  email,
  name,
  photo,
  info = {},
) => {
  info.reg_from = 'website';
  var endPoints = Init();
  return endPoints.GoogleRegister(user_id, email, name, photo, info);
};

export const GoogleLogin = async (user_id, email, name, photo) => {
  var endPoints = Init();
  return new Promise(async function (resolve, reject) {
    try {
      let accessToken = await endPoints.GoogleLogin(
        user_id,
        email,
        name,
        photo,
      );

      if (accessToken) {
        setItemToLocalStorage('AC', accessToken);

        let { userInfo, subInfo } = await getUserInfo();
        setItemToLocalStorage('userInfo', userInfo);

        if (typeof userInfo == 'object' && typeof subInfo == 'object') {
          resolve('loginsuccess');
        } else {
          reject('Error Occured');
        }
      }
    } catch (error) {
      console.log('error =>', error);
      if (error.error_description) {
        reject(error?.error_description);
      }
      reject(error?.response?.data || error);
    }
  });
};

export const SubMatchAndChange = async info => {
  var endPoints = Init();
  return endPoints.SubMatchAndChange(info);
};

export const SubscriptionInfo = async info => {
  var endPoints = Init();
  return endPoints.SubscriptionInfo();
};

export const validateApplePaySession = async (
  appleUrl,
  domainName,
  merchantIdentifier,
) => {
  var endPoints = Init();
  return endPoints.validateApplePaySession(
    appleUrl,
    domainName,
    merchantIdentifier,
  );
};

export async function sendOTP(data) {
  var endPoints = Init();
  return endPoints.CreateDCBContract(data, {
    'dev-secret': 'BLI3ebncfEV6I6YzK9PL',
  });
}

export async function validateOTP(data) {
  var endPoints = Init();
  // return {
  //   status: true,
  // };
  return endPoints.VerifyDCBContract(data, {
    'dev-secret': 'BLI3ebncfEV6I6YzK9PL',
  });
}
// subscriptionContractId;
export async function resendOTP(data) {
  var endPoints = Init();
  return endPoints.ResendDCBPin(data, { 'dev-secret': 'BLI3ebncfEV6I6YzK9PL' });
}

export async function getExtPromo() {
  var endPoints = Init();
  return endPoints.GetExtPromo();
}


export async function getGroupPublicInfo(entityId) {
  var endPoints = Init();
  return endPoints.Challenge.getPublicInfo(entityId);
}

export async function registerAndJoin(id, data) {
  var endPoints = Init();
  return endPoints.Challenge.registerAndJoin(id, data);
}

export const GetCustomerPortalToken = async (token) => {
  var endPoints = Init(token);
  return endPoints.getSubsbasePortalToken();
};
